import React, { useState } from "react";
import { Row, Col, Form, Alert, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Fade } from "react-awesome-reveal";

import Section from "../../components/Section";

const gatewayURL =
    "https://hqc0ir2wa3.execute-api.us-east-2.amazonaws.com/v1/CC-ContactForm";

const Contact = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isError, setIsError] = useState(false);

    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        setIsLoading(true);

        const requestOptions = {
            method: "POST",
            mode: "no-cors",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" },
        };
        fetch(gatewayURL, requestOptions)
            .then((res) => {
                if (res.status === 0) {
                    setIsComplete(true);
                } else {
                    console.log("error: ", res);
                    setIsError(true);
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                setIsError(true);
                setIsLoading(false);
            });
    };

    const animatedExitClass = (isComplete) => {
        return isComplete ? "animate__animated animate__fadeOutRightBig" : "";
    };

    return (
        <Section
            id="contact"
            title="Contact Us"
            description="Contact us today to see how we can help you boost your business."
        >
            <Row>
                <Col lg={7}>
                    <div className="mb-5 mb-lg-0">
                        <div className="text-center">
                            <Fade>
                                <img
                                    src="/images/contact-us.png"
                                    alt=""
                                    className="img-fluid d-block w-75 mx-auto"
                                />
                            </Fade>
                        </div>
                    </div>
                </Col>

                <Col lg={5}>
                    {!isComplete ? (
                        <div className={animatedExitClass(isComplete)}>
                            <h4 className="line-height-1_4 mb-4">
                                Get in Touch
                            </h4>
                            <div className="custom-form mt-4 mt-lg-0">
                                <div id="message"></div>
                                <Form
                                    onSubmit={handleSubmit(onSubmit)}
                                    name="contact-form"
                                    id="contact-form"
                                >
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className="app-label">
                                                <Form.Label
                                                    for="name"
                                                    className="text-muted"
                                                >
                                                    Your Name
                                                </Form.Label>
                                                <input
                                                    name="name"
                                                    id="name"
                                                    type="text"
                                                    className="form-control"
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                />
                                                {errors.name ? (
                                                    <Alert variant="warning">
                                                        Please enter your name.
                                                    </Alert>
                                                ) : (
                                                    ""
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="app-label">
                                                <Form.Label
                                                    for="business_name"
                                                    className="text-muted"
                                                >
                                                    Business Name
                                                </Form.Label>
                                                <input
                                                    name="business_name"
                                                    id="business_name"
                                                    type="text"
                                                    className="form-control"
                                                    ref={register()}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="app-label">
                                                <Form.Label
                                                    for="email"
                                                    className="text-muted"
                                                >
                                                    Email Address
                                                </Form.Label>
                                                <input
                                                    name="email"
                                                    id="email"
                                                    type="email"
                                                    className="form-control"
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                />
                                                {errors.email ? (
                                                    <Alert variant="warning">
                                                        Please enter your Email
                                                        address so we can
                                                        contact you.
                                                    </Alert>
                                                ) : (
                                                    ""
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="app-label">
                                                <Form.Label
                                                    for="phone"
                                                    className="text-muted"
                                                >
                                                    Phone Number ex: (123)
                                                    456-7890
                                                </Form.Label>
                                                <input
                                                    name="phone"
                                                    id="phone"
                                                    type="tel"
                                                    className="form-control"
                                                    ref={register({
                                                        pattern: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
                                                    })}
                                                />
                                                {errors.phone ? (
                                                    <Alert variant="warning">
                                                        Please check your phone
                                                        number.
                                                    </Alert>
                                                ) : (
                                                    ""
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="app-label">
                                                <Form.Label
                                                    for="message"
                                                    className="text-muted"
                                                >
                                                    Message
                                                </Form.Label>
                                                <textarea
                                                    name="message"
                                                    id="message"
                                                    rows="4"
                                                    className="form-control"
                                                    ref={register({
                                                        required: true,
                                                    })}
                                                ></textarea>
                                                {errors.message ? (
                                                    <Alert variant="warning">
                                                        Please enter a message.
                                                    </Alert>
                                                ) : (
                                                    ""
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <button
                                                type="submit"
                                                id="submit"
                                                name="send"
                                                className="btn btn-primary"
                                                disabled={isLoading}
                                            >
                                                {!isLoading ? (
                                                    "Send Message"
                                                ) : (
                                                    <>
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        {" Sending message"}
                                                    </>
                                                )}
                                            </button>
                                            {isError ? (
                                                <Alert
                                                    className="mt-3"
                                                    variant="warning"
                                                >
                                                    Uh-Oh! Something went wrong
                                                    submitting your message
                                                </Alert>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    ) : (
                        <div className="text-center">
                            <Alert variant="success">
                                Thank you for reaching out! We will be in
                                contact with you soon!
                            </Alert>
                        </div>
                    )}
                </Col>
            </Row>
        </Section>
    );
};

export default Contact;
