import * as React from "react";

import { Col } from "react-bootstrap";
import { FiGrid } from "@react-icons/all-files/fi/FiGrid";
import { FiRadio } from "@react-icons/all-files/fi/FiRadio";
import { FiMonitor } from "@react-icons/all-files/fi/FiMonitor";

const Panel1 = () => {
    return (
        <Col lg={4}>
            <h2 className="font-weight-normal line-height-1_4 mb-4">
                A Small &amp; Agile Firm Focused On{" "}
                <span className="font-weight-medium">Your Business</span>
            </h2>
            <p className="text-muted mb-4">
                We keep our client list small so we can fully focus on your
                business needs. Discover how we can leverage technology to
                enhance and grow your business.
            </p>
            <p className="f-17 text-muted">
                <span className="mr-2">
                    <FiMonitor />
                </span>
                Custom Web Development
            </p>
            <p className="f-17 text-muted">
                <span className="mr-2">
                    <FiGrid />
                </span>
                Process Analysis and Improvement
            </p>
            <p className="f-17 text-muted mb-4">
                <span className="mr-2">
                    <FiRadio />
                </span>
                Engagement Strategies
            </p>
            <a href="#contact" className="read-more f-18">
                Get In Touch{" "}
                <span className="ml-2 right-icon f-24">&#8594;</span>
            </a>
        </Col>
    );
};

export default Panel1;
