import * as React from "react";
import Section from "../../../components/Section";
import { Row } from "react-bootstrap";
import Panel1 from "./panel-1";
import Panel2 from "./panel-2";

const AboutUs = () => {
    return (
        <Section title="About Us" description="" id="about-us">
            <Row>
                <Panel1 />
                <Panel2 />
            </Row>
        </Section>
    );
};

export default AboutUs;
