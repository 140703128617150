import * as React from "react";

const Hero = ({ id, sideImg, children, className = "hero-1-bg" }) => {
    return (
        <section className={className} id={id}>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6">{children}</div>
                    <div className="col-lg-6 col-md-10">
                        <div className=" mt-5 mt-lg-0">
                            <img
                                src={sideImg}
                                alt="Graphic representation"
                                className="img-fluid d-block mx-auto"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
