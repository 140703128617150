import * as React from "react";
import { Col } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";

const FeaturePanel = ({ title, description, tag, image }) => {
    return (
        <div className="card border-0">
            <div className="bg-soft-warning about-img rounded">
                <img
                    className="img-fluid d-block mx-auto"
                    src={image}
                    alt=""
                ></img>
            </div>
            <p class="text-uppercase text-muted mb-2 f-13">{tag}</p>
            <h4 className="f-18">{title}</h4>
            <p className="text-muted">{description}</p>
        </div>
    );
};

const Panel2 = () => {
    const panels = [
        {
            title: "Custom Development",
            description:
                "We build applications and web sites to satisfy your business needs. We focus on you and your business exclusively to ensure you get the attention your business needs to grow.",
            image: "/images/web-development.png",
            tag: "Development",
        },
        {
            title: "Data Analysis and Process Improvement",
            description:
                "We provide tools to deliver data-driven insights to help you improve processes and gain efficiencies - while keeping costs at a minimum.",
            image: "/images/data-analysis.png",
            tag: "Analysis",
        },
    ];
    return panels.map((panel) => {
        return (
            <Col lg={4}>
                <Fade>
                    <FeaturePanel
                        title={panel.title}
                        description={panel.description}
                        image={panel.image}
                    />
                </Fade>
            </Col>
        );
    });
};

export default Panel2;
