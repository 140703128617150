import * as React from "react";
import Section from "../../../components/Section";
import { CardColumns, Card } from "react-bootstrap";
import { FcAssistant } from "@react-icons/all-files/fc/FcAssistant";
import { FcServices } from "@react-icons/all-files/fc/FcServices";
import { FcSerialTasks } from "@react-icons/all-files/fc/FcSerialTasks";
import { Fade } from "react-awesome-reveal";

const ServiceCard = ({ service }) => {
    const { id, title, description, icon, link } = service;
    return (
        <Fade>
            <Card className="service-box text-center p-4 mb-4" id={id}>
                <div
                    className="service-icon-bg mx-auto avatar-xxl p-4"
                    style={{
                        backgroundImage: "url(/images/service-icon-bg.png)",
                    }}
                >
                    <div className="service-box-icon justify-content-center">
                        {icon}
                    </div>
                </div>
                <Card.Body>
                    <Card.Title className="service-title">{title}</Card.Title>
                    <Card.Text className="service-subtitle">
                        {description}
                    </Card.Text>
                </Card.Body>
                {link ? (
                    <a href={link.href} class="read-more">
                        {link.text}
                        <span class="right-icon ml-2">&#8594;</span>
                    </a>
                ) : (
                    ""
                )}
            </Card>
        </Fade>
    );
};

const ServicesSection = () => {
    const services = [
        {
            title: "Awesome Support",
            description:
                "We provide individualized 1-on-1 support for all of our products. We keep our client list small in order to provide your business the attention it deserves.",
            icon: <FcAssistant size={64} />,
        },
        {
            title: "Web Development",
            description:
                "We develop custom web applications to solve specific problems facing your business, from establishing a web presence to fully customized data-driven apps.",
            icon: <FcServices size={64} />,
        },
        {
            title: "Software Consulting",
            description:
                "We offer professional consulting to determine how your business can best leverage software technology to improve critical processes and gain efficiencies.",
            icon: <FcSerialTasks size={64} />,
        },
    ];
    return (
        <Section
            title="Our Services"
            description="We tailor custom products and services to satisfy your business needs."
            classes="bg-light"
            id="services"
        >
            <CardColumns>
                {services.map((service, idx) => {
                    return (
                        <ServiceCard service={service} id={`service-${idx}`} />
                    );
                })}
            </CardColumns>
        </Section>
    );
};

export default ServicesSection;
