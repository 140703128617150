import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Section = ({ id, title, description, children, classes = "" }) => {
    return (
        <section className={`section ${classes}`} id={id}>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={7}>
                        <div className="text-center mb-5">
                            <h2 className="">{title}</h2>
                            <p className="text-muted">{description}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>{children}</Container>
        </section>
    );
};

export default Section;
