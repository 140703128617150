import * as React from "react";
import Hero from "../../../components/Hero";
import HeroImg1 from "../../../images/hero/cc-working-process.png";
import { AttentionSeeker } from "react-awesome-reveal";

const HomeHero = () => {
    return (
        <Hero sideImg={HeroImg1} backgroundImg="" id="home">
            <h1 className="hero-1-title font-weight-medium text-dark mb-4">
                We Help You To Boost Your Business
            </h1>
            <p className="text-muted mb-4 pb-3">
                We focus on you and your business. We provide custom products
                tailored to your needs while giving your business the individual
                attention it deserves.
            </p>
            <AttentionSeeker effect="rubberBand" delay={3000}>
                <a href="#contact" className="btn btn-primary">
                    Boost Your Business{" "}
                    <span className="ml-2 right-icon">&#8594;</span>
                </a>
            </AttentionSeeker>
        </Hero>
    );
};

export default HomeHero;
