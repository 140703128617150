import * as React from "react";

import MainLayout from "../../layouts/main";
import HomeHero from "./Hero";
import ServicesSection from "./Services";
import AboutUs from "./About";
import Contact from "./Contact";

const navMenu = [
    { text: "Services", href: "#services" },
    { text: "About Us", href: "#about-us" },
    { text: "Contact", href: "#contact" },
];

// markup
const IndexPage = () => {
    return (
        <MainLayout menu={navMenu}>
            <HomeHero />
            <ServicesSection />
            <AboutUs />
            <Contact />
        </MainLayout>
    );
};

export default IndexPage;
